import React from "react"
import Section from "@components/section"
import { Box } from "rebass"

const UnleashThePower = ({ data }) => (
  <React.Fragment>
    <div
      style={{
        textAlign: "center",
        marginTop: "122px",
        fontSize: "2rem",
        fontWeight: "600",
        lineHeight: "1.3",
        letterSpacing: -1,
      }}
      id="features"
    >
      10 Things You Can Do With Dynobase (That Can't Be Done Inside AWS DynamoDB
      Console)
    </div>
    <Section
      title="Dataset exploration assistance"
      desc="Dynobase knows your table data structure perfectly. It auto-suggests you available attributes, so you don't have to remember them."
      imageName="autosuggest.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Integrate With Your Workflow"
      desc="Dynobase allows exporting of all operations into CLI and popular languages SDK formats. No more writing queries; just copy and paste straight into your favorite IDE."
      imageName="params.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Polyglot Codegen"
      desc="Dynobase helps you to code by providing classes and structures of your DynamoDB items in the language of your choice."
      imageName="codegen.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Easily Switch Between Tables Across Accounts and Regions"
      desc="No more re-logging, changing roles, and juggling between AWS accounts. All your data in one place. Profiles are loaded from ~/.aws/ directory automatically. Support for IAM roles, SSO, AWS Vault, and temporary security credentials included."
      imageName="profiles.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Raw Query Access"
      desc="Dynobase allows you to write queries not only in PartiQL but also in DynamoDB's native Query Language. The expressiveness of code and instant visual feedback."
      imageName="raw_query.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Importer"
      desc="Import your data from JSON or CSV files to DynamoDB without writing complex scripts. Configure datatypes, mapping, and a lot more!"
      imageName="import.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Intuitive User Experience"
      desc="Explore datasets with ease and speed. Dynobase offers advanced filtering capabilities, autocomplete attribute names and better performance than AWS console."
      imageName="betterux.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Terminal"
      desc="Thanks to Dynobase's Terminal, you can blur the line between UI and Code. Slice and dice your data inside Dynobase to get results filtered and transformed beyond normal capabilities."
      imageName="terminal.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Visual Query Builder"
      desc="Not familiar with DynamoDB's query language? No problem - Dynobase's visual query builder makes it easy to construct and run queries."
      imageName="automaticquery.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Bookmarks and history"
      desc="Save and load frequently used queries and scans, use the history of actions just like in your web browser."
      imageName="history.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />

    <Box mt={62}>
      <p style={{ textAlign: "center" }}>
        These are just 10 highlighted features,{" "}
        <a href="https://dynobase.dev/#features" target="_blank">
          there's a whole lot more
        </a>
        .
      </p>
    </Box>
  </React.Fragment>
)

export default UnleashThePower
