import React, { useState } from "react"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import { StaticImage } from "gatsby-plugin-image"

const Hero = props => {
  const [isVideo, setVideo] = useState(false)

  return (
    <React.Fragment>
      <h1
        style={{
          color: "white",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          lineHeight: "1.3",
          marginTop: "62px",
        }}
      >
        Graduate from AWS DynamoDB <br /> Console to Dynobase
      </h1>
      <p
        style={{
          color: "white",
          fontWeight: 400,
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        It's like switching from Notepad to Visual Studio Code. Developers are
        moving their DynamoDB workflow to Dynobase for an advanced and faster
        DynamoDB experience that truly delivers.
      </p>
      {!isVideo ? (
        <StaticImage
          src="../../../images/dynobase.png"
          fadeIn={false}
          style={{
            boxShadow: "6px 8px 12px 7px hsla(0, 0%, 0%, 0.12)",
            borderRadius: 5,
          }}
        />
      ) : (
        <div
          style={{
            overflow: "hidden",
            paddingTop: "56.25%",
            position: "relative",
            marginBottom: "4.9em",
          }}
        >
          {" "}
          <iframe
            style={{
              border: "0",
              height: "100%",
              left: "0",
              position: "absolute",
              top: "0",
              width: "100%",
            }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/SGHBz6qe_YE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="dynamodb gui client"
            allowFullScreen
            autoPlay="1"
          ></iframe>
        </div>
      )}
      {!isVideo && (
        <p
          style={{
            textAlign: "center",
            color: "rgba(0,0,0,0.7)",
            fontSize: "0.7em",
            cursor: "pointer",
            marginTop: "0.8em",
            marginBottom: "2.5em",
          }}
          onClick={() => setVideo(true)}
        >
          See Dynobase in action - Click to Play Video
        </p>
      )}
      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]}>
        <DesktopDownloadForm data={props.data} />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
          lineHeight: 1.3,
        }}
      >
        7-day free trial. No credit card required
        <br />
        Available for macOS, Linux and Windows
      </p>
    </React.Fragment>
  )
}

export default Hero
