import React from "react"
import { Text } from "rebass"
import { getImage } from "../../../../utils/getImage"
import Tesimonial from "./Tesimonial"

const Testimonials = ({ customMt, data }) => (
  <React.Fragment>
    <Text
      sx={{
        marginBottom: [42, 42, 52],
        marginTop: customMt ? customMt : [92, 92, 132],
      }}
      style={{
        textAlign: "center",
        fontSize: "1.75rem",
        fontWeight: "600",
        lineHeight: "1.1",
      }}
    >
      Trusted by top DynamoDB experts
    </Text>
    <div style={{ marginBottom: "200px" }}>
      <Tesimonial
        author="Alex DeBrie, AWS Data Hero"
        authorSub="Author of DynamoDBBook.com and DynamoDBGuide.com"
        authorLink="https://twitter.com/alexdebrie"
        imageName="alex.jpg"
        allImageSharpNodes={data.allImageSharp.nodes}
      >
        <p style={{ marginBottom: 0 }}>
          I love using Dynobase to explore my tables with a native app rather
          than going to the AWS console in my browser.
          <br />
          <br /> One of my favorite features is the ability to save common
          queries for simple use later. As you're implementing your data model,
          I recommend saving queries for each of your application's access
          patterns to make it easy to debug collections of items later on.{" "}
          <br />
          <br />
          Dynobase is quickly becoming{" "}
          <b>an essential part of working with DynamoDB</b>.
        </p>
      </Tesimonial>
      <Tesimonial
        author="Yan Cui, AWS Serverless Hero"
        authorLink="https://twitter.com/theburningmonk"
        authorSub="Author of Production-Ready Serverless, Developer Advocate at Lumigo"
        imageName="yan.png"
        allImageSharpNodes={data.allImageSharp.nodes}
      >
        <p style={{ marginBottom: 0 }}>
          Dynobase is by far{" "}
          <b>the most intuitive UI tool I've used for DynamoDB</b>, it's leaps
          and bounds better than the AWS console and makes it really easy to
          manage DynamoDB tables in a multi-region, multi-account environment.{" "}
          <br />
          <br />
          It's made my life so much easier and allowed me to focus on the things
          that actually matter to my clients.
        </p>
      </Tesimonial>
      <Tesimonial
        author="Erik Peterson"
        authorLink="https://twitter.com/silvexis"
        authorSub="Founder & CTO of CloudZero"
        imageName="erik.jpeg"
        allImageSharpNodes={data.allImageSharp.nodes}
      >
        <p style={{ marginBottom: 0 }}>
          There are two amazing things about Dynobase. <br />
          <br /> The first is that it took so long for such a great DynamoDB
          client to finally arrive and the second is that it was so worth the
          wait.
        </p>
      </Tesimonial>
    </div>
  </React.Fragment>
)

export default Testimonials
